import { lazy, Suspense, useEffect, createContext, useReducer, Fragment } from 'react';
import { BrowserRouter, Switch, Route, useLocation } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import Navbar from 'components/Navbar';
import NavbarWork from 'components/NavbarWork';
import ThemeProvider from 'components/ThemeProvider';
import { tokens } from 'components/ThemeProvider/theme';
import VisuallyHidden from 'components/VisuallyHidden';
import { useLocalStorage } from 'hooks';
import { msToNum } from 'utils/style';
import { reflow } from 'utils/transition';
import prerender from 'utils/prerender';
import { initialState, reducer } from 'app/reducer';
import './reset.css';
import './index.css';

const Home = lazy(() => import('pages/Home'));
const ProjectAutosport = lazy(() => import('pages/Autosport'));
const ProjectMulberry = lazy(() => import('pages/Mulberry'));
const ProjectAviva = lazy(() => import('pages/Aviva'));
const ProjectOasis = lazy(() => import('pages/Oasis'));
const ProjectTkmaxx = lazy(() => import('pages/Tkmaxx'));
const ProjectGucci = lazy(() => import('pages/Gucci'));
const ProjectGoodyear = lazy(() => import('pages/Goodyear'));
const ProjectSainsburys = lazy(() => import('pages/Sainsburys'));
const ProjectBarbour = lazy(() => import('pages/Barbour'));
const ProjectDrmartens = lazy(() => import('pages/Drmartens'));
const ProjectVarious = lazy(() => import('pages/Various'));
const Info = lazy(() => import('pages/Info'));
const Skills = lazy(() => import('pages/Skills'));
const Clients = lazy(() => import('pages/Clients'));
const Random = lazy(() => import('pages/Random'));

export const AppContext = createContext();
export const TransitionContext = createContext();



const App = () => {
  const [storedTheme] = useLocalStorage('theme', 'light');
  const [state, dispatch] = useReducer(reducer, initialState);


  useEffect(() => {
    dispatch({ type: 'setTheme', value: storedTheme });
  }, [storedTheme]);

  return (
    <AppContext.Provider value={{ ...state, dispatch }}>
      <ThemeProvider themeId={state.theme}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ThemeProvider>
    </AppContext.Provider>
  );
};

const AppRoutes = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Fragment>
      <Helmet>
        <link rel="canonical" href={`http://rowleymacklin.com/${pathname}`} />
      </Helmet>
      <VisuallyHidden showOnFocus as="a" className="skip-to-main" href="#MainContent">
        Skip to main content
      </VisuallyHidden>
      <NavbarWork location={location} />
      <Navbar location={location} />
      <TransitionGroup component="main" className="app" tabIndex={-1} id="MainContent">
        <Transition
          key={pathname}
          timeout={msToNum(tokens.base.durationS)}
          onEnter={reflow}
        >
          {status => (
            <TransitionContext.Provider value={{ status }}>
              <div className={classNames('app__page', `app__page--${status}`)}>
                <Suspense fallback={<Fragment />}>
                  <Switch location={location}>
                    <Route exact path="/" component={Home} />
                    <Route path="/projects/autosport" component={ProjectAutosport} />
                    <Route path="/projects/mulberry" component={ProjectMulberry} />
                    <Route path="/projects/aviva" component={ProjectAviva} />
                    <Route path="/projects/oasis" component={ProjectOasis} />
                    <Route path="/projects/tkmaxx" component={ProjectTkmaxx} />
                    <Route path="/projects/gucci" component={ProjectGucci} />
                    <Route path="/projects/goodyear" component={ProjectGoodyear} />
                    <Route path="/projects/sainsburys" component={ProjectSainsburys} />
                    <Route path="/projects/barbour" component={ProjectBarbour} />
                    <Route path="/projects/drmartens" component={ProjectDrmartens} />
                    <Route path="/projects/various" component={ProjectVarious} />
                    <Route path="/info" component={Info} />
                    <Route path="/skills" component={Skills} />
                    <Route path="/clients" component={Clients} />
                    <Route path="/random" component={Random} />
                  </Switch>
                </Suspense>
              </div>
            </TransitionContext.Provider>
          )}
        </Transition>
      </TransitionGroup>
    </Fragment>
  );
};

export default App;
