export const workLinks = [
  {
    label: 'Autosport',
    pathname: '/',
    hash: '#Autosport',
  },  
  {
    label: 'Mulberry',
    pathname: '/',
    hash: '#Mulberry',
  }, 
  {
    label: 'Aviva',
    pathname: '/',
    hash: '#Aviva',
  },  
  {
    label: 'Oasis',
    pathname: '/',
    hash: '#Oasis',
  }, 
  {
    label: 'TKMaxx',
    pathname: '/',
    hash: '#TKMaxx',
  },  
  {
    label: 'Gucci',
    pathname: '/',
    hash: '#Gucci',
  }, 
  {
    label: 'Goodyear',
    pathname: '/',
    hash: '#Goodyear',
  },  
  {
    label: 'Sainsbury’s',
    pathname: '/',
    hash: '#Sainsburys',
  },  
  {
    label: 'Barbour',
    pathname: '/',
    hash: '#Barbour',
  },  
  {
    label: 'Dr Martens',
    pathname: '/',
    hash: '#DrMartens',
  }, 
  {
    label: 'Various',
    pathname: '/',
    hash: '#Various',
  },  
];
